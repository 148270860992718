import GenericCrudTable from "../components/tables/genericCrudTable";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DropDownSubjectSearch from "../components/forms/inputs/dropDownSubjectSearch";
import { useRef, useState } from "react";
import { IDropDownOption } from "../types/DropDown";
import Restricted from "../consumer/restricted";
import styles from "./preventiveCareList.module.css";
import { getTranslatedStatusOptions } from "../helper/statusOptions";
/*eslint-disable */
import { Box, Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
/*eslint-enable */
import { BarChart } from "@mui/x-charts/BarChart";
import { ChartsLegend, legendClasses } from "@mui/x-charts/ChartsLegend";
import { ResponsiveChartContainer } from "@mui/x-charts/ResponsiveChartContainer";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

export default function PreventiveCareList() {
  /*eslint-disable */
  const [options, setOptions] = useState<IDropDownOption[]>([]);
  const [refreshTable, setRefreshTable] = useState<any>(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [chosenFilter, setChosenFilter] = useState<string>("");
  const [subjectId, setSubjectId] = useState<string>("");
  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];

  const statusFilterOptions = getTranslatedStatusOptions();

  const navigate = useNavigate();
  /*eslint-enable */

  const { t } = useTranslation();
  const appointmentRef = useRef(null);

  const replaceDate = (id: any, dateTime = false) => {
    let date = "";
    if (dateTime === true) {
      date = new Date(id).toLocaleString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    } else {
      date = new Date(id).toLocaleString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    }

    if (id === null || id === undefined) {
      return "";
    } else return date;
  };

  const handleSubjectSelect = (selectedOption: any) => {
    if (
      selectedOption &&
      typeof selectedOption === "object" &&
      selectedOption.value
    ) {
      setSubjectId(selectedOption.value);
    }
  };
  const refreshParentTable = () => {
    setRefreshTable(true);
  };
  const resetAsyncInput = () => {
    setSubjectId("");
  };

  function getUniqueBuildings(data: any) {
    const uniqueBuildings = new Map();
    const result: any = [];

    if (Array.isArray(data.department_set)) {
      data.department_set.forEach((department: any) => {
        const building = department.building;

        if (building && !uniqueBuildings.has(building.id)) {
          uniqueBuildings.set(building.id, building);
          result.push(building);
        }
      });
    }

    return result;
  }
  const customDetailPanel = (data: any) => {
    const deadlineData = data?.original?.subjectexaminationdeadline_set;
    return (
      <GenericCrudTable
        readOnlyMode={subdomain !== "admin"}
        disableGlobalFilter={true}
        entity={"subjectExaminationDeadline"}
        customTableData={deadlineData}
        additionalRoutePartsForGet={{ preventive_care_registry: "/" }}
        searchId={subjectId}
        refreshTableAfterAction={refreshParentTable}
        customPageSize={"100"}
        additionalUrlParameter={{
          include_draft: "true",
          company_subject_id: subjectId,
        }}
        expandedDepth={true}
        disableAddItem={true}
        columns={[
          { header: "ID", accessorKey: "id", id: "id" },
          {
            header: t("examination"),
            accessorKey: "examination.name",
            id: "examination",
            customColumnFilterId: "examination_name",
            entity: "examination",
            accessorFn: (row: any) => {
              if (row.examination) {
                return row?.examination?.name;
              }
            },
          },

          {
            header: t("last_executed_on"),
            accessorKey: "last_executed_on",
            id: "last_executed_on",
            datePicker: true,
            sortingFn: "datetime",
            required: true,
            errorMessage: t("field_required"),
            filterVariant: "date",
            accessorFn: (row: any) => {
              const lastAppointmentId = row.last_appointment_id;
              const lastExecutedDate = replaceDate(row.last_executed_on);

              let output: any = lastExecutedDate;
              if (
                lastAppointmentId !== null &&
                lastAppointmentId !== undefined
              ) {
                output = (
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.link}
                    to={`/appointmentDashboard/${lastAppointmentId}`}
                  >
                    {lastExecutedDate}
                  </Link>
                );
              }
              return <span>{output}</span>;
            },
          },
          {
            header: t("next_appointment_on"),
            accessorKey: "next_appointment_on",
            id: "next_appointment_on",
            datePicker: true,

            accessorFn: (row: any) => {
              const nextAppointmentId = row.next_appointment_id;
              const nextAppointmentOn = replaceDate(row.next_appointment_on);

              let output: any = nextAppointmentOn;
              if (
                nextAppointmentId !== null &&
                nextAppointmentId !== undefined
              ) {
                output = (
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.link}
                    to={`/appointmentDashboard/${nextAppointmentId}`}
                  >
                    {nextAppointmentOn}
                  </Link>
                );
              }
              return <span>{output}</span>;
            },
          },
          {
            header: t("valid_until"),
            accessorKey: "valid_until",
            id: "valid_until",
            enableForCreate: true,
            datePicker: true,
            Cell: ({ cell }: any) => {
              return <span>{replaceDate(cell.getValue())}</span>;
            },
            sortingFn: "datetime",
            // defaultDate: DEFAULTPICKERDATE.TODAY,
            required: true,
            errorMessage: t("field_required"),
            filterVariant: "date",
          },

          {
            header: t("eligibility"),
            accessorKey: "eligibility",
            id: "eligibility",
            size: 255,
            textarea: true,
            enableColumnFilter: false,
            enableForCreate: true,
          },
          {
            header: t("status"),
            accessorKey: "status",
            id: "status",
            Cell: ({ cell }: any) => {
              const value = cell.getValue();

              if (value === "valid") {
                return <span style={{ color: "#008c7d" }}>{t(value)}</span>;
              } else if (value === "planned") {
                return <span style={{ color: "#ff8353" }}>{t(value)}</span>;
              } else if (value === "empty") {
                return <span>{t(value)}</span>;
              }
              return <span style={{ color: "#dc3545" }}>{t("overdue")}</span>;
            },
          },
        ]}
      />
    );
  };

  const chartLegendHeader = () => {
    const series: any = [
      {
        label: t("valid"),
        color: "#008c7d",
        type: "bar",
        data: [],
        id: t("valid"),
      },
      {
        label: t("planned"),
        color: "#ff6428",
        type: "bar",
        data: [],
      },
      {
        label: t("overdue"),
        color: "#dc3545",
        type: "bar",
        data: [],
      },
      {
        label: t("empty"),
        color: "#bcbcbc",
        type: "bar",
        data: [],
      },
    ];

    return (
      <>
        <span> {t("preventive_care_overview")}</span>
        <ResponsiveChartContainer series={series} width={600} height={40}>
          <ChartsLegend
            direction="row"
            position={{
              horizontal: "left",
              vertical: "top",
            }}
          />
        </ResponsiveChartContainer>
      </>
    );
  };

  const handleFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    selectedFilter: string
  ) => {
    setDateRange([null, null]);
    setChosenFilter(selectedFilter);
  };
  console.log("dateR  ", dateRange);

  const customActions = (
    <div style={{ display: "flex" }}>
      <ToggleButtonGroup
        color="primary"
        value={chosenFilter}
        exclusive
        onChange={handleFilterChange}
        aria-label="Platform"
      >
        <ToggleButton
          value="last_executed_on"
          sx={{ textTransform: "inherit" }}
        >
          {t("last_executed_on")}
        </ToggleButton>
        <ToggleButton
          value="next_appointment_on"
          sx={{ textTransform: "inherit" }}
        >
          {t("next_appointment_on")}
        </ToggleButton>

        <ToggleButton value="valid_until" sx={{ textTransform: "inherit" }}>
          {t("valid_until")}
        </ToggleButton>
        <ToggleButton value="" sx={{ textTransform: "inherit" }}>
          <RemoveCircleOutlineIcon />
        </ToggleButton>
        <DatePicker
          selectsRange={true}
          locale={"de"}
          dateFormat="dd/MM/yyyy"
          calendarStartDay={1}
          withPortal
          startDate={startDate}
          disabled={chosenFilter === ""}
          endDate={endDate}
          className={styles.datePicker}
          onChange={(update: any) => {
            setDateRange(update);
          }}
          placeholderText={t(chosenFilter)}
          isClearable={true}
          showMonthDropdown={true}
          showYearDropdown={true}
        />
      </ToggleButtonGroup>
    </div>
  );

  const handleCsvExport = () => {
    let href = `/api/v1/subjectExaminationDeadline/export_preventive_care_registry/?company_subject_id=${subjectId}`;

    if (subdomain === "manage") {
      href = `/api/v1/subjectExaminationDeadline/export_preventive_care_registry/`;
    }
    window.location.href = href;
  };

  const customActionButton = (
    <Button color="secondary" variant="contained" onClick={handleCsvExport}>
      {t("csv_export")}
    </Button>
  );

  const additionalUrlParameters: any = {};
  additionalUrlParameters["include_draft"] = true;
  additionalUrlParameters["company_subject_id"] = subjectId;

  if (chosenFilter !== "") {
    if (startDate !== null && endDate !== null) {
      const tempStartDate = new Date(startDate).toISOString();
      const tempEndDate = new Date(endDate).toISOString();
      additionalUrlParameters[`${chosenFilter}_range`] = [
        dayjs(tempStartDate).format("YYYY-MM-DD"),
        dayjs(tempEndDate).format("YYYY-MM-DD"),
      ];
    }
  }
  return (
    <Restricted permissionRequired="frontend_view_preventive_care">
      {subdomain === "admin" && (
        <DropDownSubjectSearch
          wrapperClassName={styles.dropDownSubjectSearch}
          options={options}
          onChange={handleSubjectSelect}
          subdomain={subdomain}
          isClearable={true}
          resetInput={resetAsyncInput}
          autofocus={true}
          customRef={appointmentRef}
          useGlobalState={true}
          queryParameter="companies"
        />
      )}
      {((subdomain === "admin" && subjectId) ||
        (subdomain !== "admin" && !subjectId)) && (
        <GenericCrudTable
          customTopToolBarAction={customActions}
          customTopToolBarActionButton={customActionButton}
          readOnlyMode={subdomain !== "admin"}
          refreshTable={refreshTable}
          showRowActions={false}
          allowColumnFiltering={true}
          showColumnFilterbyDefault={false}
          withSubjectSearch={true}
          heading={t("preventive_care")}
          searchId={subjectId}
          subdomain={subdomain}
          entity={"subjectExaminationDeadline"}
          additionalRoutePartsForGet={{ preventive_care_registry: "/" }}
          additionalUrlParameter={additionalUrlParameters}
          expandedDepth={true}
          customDetailPanel={customDetailPanel}
          columns={[
            { header: "ID", accessorKey: "id", id: "id" },
            {
              header: t("subject"),
              accessorKey: "person.user.name",
              id: "subject",
              enableForCreate: true,
              asyncEntity: "subject",
              asyncDropDown: true,
              required: true,
              customColumnFilterId: "subject_name",
              errorMessage: t("field_required"),
              queryParameter: "users",
            },
            {
              header: t("departments"),
              accessorKey: "department_set",
              customColumnFilterId: "department_name",
              customFilterParameter: "_icontains",
              enableSorting: false,
              id: "department_set",
              accessorFn: (row: any) => {
                const departments: any = [];
                if (row.department_set) {
                  for (const key in row.department_set) {
                    if (row.department_set[key].name) {
                      departments.push(row.department_set[key].name);
                    }
                  }
                }
                return departments.join(", ");
              },
            },
            {
              header: t("building_location"),
              accessorKey: "building",
              enableColumnFilter: false,
              id: "building",
              accessorFn: (row: any) => {
                const buildings = getUniqueBuildings(row);

                return (
                  <>
                    {buildings.map((building: any) => (
                      <div key={building.id}>
                        <span className={styles.buildingName}>
                          {building.name}
                        </span>
                        <br />
                        <span>
                          {building.street_house}, {building.zip}{" "}
                          {building.city}
                        </span>
                      </div>
                    ))}
                  </>
                );
              },
            },
            {
              // header: t("preventive_care_overview"),
              header: chartLegendHeader(),
              size: 400,
              enableSorting: false,
              enableColumnFilter: false,
              enableColumnActions: false,
              accessorFn: (row: any) => {
                const validObjects = row?.subjectexaminationdeadline_set.filter(
                  (obj: any) => obj.status === "valid"
                ).length;
                const plannedObjects =
                  row?.subjectexaminationdeadline_set.filter(
                    (obj: any) => obj.status === "planned"
                  ).length;
                const overdueObjects =
                  row?.subjectexaminationdeadline_set.filter(
                    (obj: any) => obj.status === "overdue"
                  ).length;
                const noDataObjects =
                  row?.subjectexaminationdeadline_set.filter(
                    (obj: any) => obj.status === "empty"
                  ).length;

                return (
                  <Box sx={{ width: "100%" }}>
                    <BarChart
                      series={[
                        {
                          data: [validObjects],
                          stack: "1",
                          label: t("valid"),
                          color: "#008c7d",
                        },
                        {
                          data: [plannedObjects],
                          stack: "1",
                          label: t("planned"),
                          color: "#ff6428",
                        },
                        {
                          data: [overdueObjects],
                          stack: "1",
                          label: t("overdue"),
                          color: "#dc3545",
                        },
                        {
                          data: [noDataObjects],
                          stack: "1",
                          label: t("empty"),
                          color: "#bcbcbc",
                        },
                      ]}
                      height={150}
                      layout="horizontal"
                      leftAxis={null}
                      bottomAxis={null}
                      sx={{
                        [`& .${legendClasses.root}`]: {
                          display: "none",
                        },
                      }}
                      tooltip={{
                        trigger: "item",
                      }}
                    />
                  </Box>
                );
              },
            },
          ]}
          additionalColumnsForCreate={[
            {
              header: t("examination"),
              accessorKey: "examination.name",
              id: "examination",
              enableForCreate: true,
              entity: "examination",
              required: true,
              customColumnFilterId: "examination_name",
              errorMessage: t("field_required"),
            },
            {
              header: t("executed_on"),
              accessorKey: "executed_on",
              id: "executed_on",
              enableForCreate: true,
              datePicker: true,
              sortingFn: "datetime",
              required: true,
              errorMessage: t("field_required"),
              filterVariant: "date",
            },
            {
              header: t("valid_until"),
              accessorKey: "valid_until",
              id: "valid_until",
              enableForCreate: true,
              datePicker: true,
              sortingFn: "datetime",
              required: true,
              errorMessage: t("field_required"),
              filterVariant: "date",
            },
          ]}
        />
      )}
    </Restricted>
  );
}
