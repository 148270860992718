import { useState, useEffect } from "react";
import Modal from "../components/modal";
import FileUpload from "../components/files/fileUpload";
import FindingForm from "../components/forms/findingForm";
import GeneralListItem from "../components/general/generaListItem";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { api } from "../helper/api";
import { useSelector } from "react-redux";
import { RootState } from "../components/state/store";
import HighFiveIcon from "../icons/highFiveIcon";
import dayjs from "dayjs";
import "./todo.css";
import FlagIcon from "../icons/flagIcon";
import GenericErrorMessageModal from "../components/forms/errorHandling/genericErrorMessageModal";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Todo() {
  const [todos, setTodos]: any = useState([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalContent, setModalContent]: any = useState("");
  const [modalTitle, setModalTitle] = useState<string>("");
  const [error, setError] = useState<any>();

  const todoListOpen = [];
  const todoListDone = [];
  const { t } = useTranslation();
  const selectedChildSubjectId = useSelector(
    (state: RootState) => state.parent.subjectId
  );

  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];

  const user = useSelector((state: RootState) => state.login);
  const child = useSelector((state: RootState) => state.parent);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const today = dayjs();

  const refreshTodosFromServer = () => {
    const additionalUrlParameters: any = {};
    if (
      subdomain === "admin" &&
      user.subjectId !== "" &&
      child.subjectId === ""
    ) {
      additionalUrlParameters["subject"] = user.subjectId;
    }

    api.genericApiRequest({
      entity: "todo",
      method: "get",
      parametersToRender: {
        additionalUrlParameters: additionalUrlParameters,
        depth: "0",
      },
      successHandler: (res: any) => {
        setTodos(res.data.results);
      },
      failHandler: (error: any) => {
        setError(error);
      },
    });
  };
  useEffect(() => {
    refreshTodosFromServer();
  }, []);

  const resetModal = () => {
    setModalOpen(false);
    setModalContent(<></>);
    setModalTitle("");
  };

  const finishTodo = (todoItem: any, onSuccessHandler?: any) => {
    const result = { impersonate_subject: selectedChildSubjectId };
    const additionalRouteParts: any = {};
    additionalRouteParts["mark_done"] = "/";
    api.genericApiRequest({
      entity: "todo",
      method: "put",
      successHandler: () => {
        if (onSuccessHandler) {
          onSuccessHandler();
        }
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: todoItem.id,
      additionalRouteParts: additionalRouteParts,
      submitData: result,
    });
  };

  const renderModalUploadContent = (todoItem: any) => {
    return (
      <FileUpload
        type={"file"}
        name={todoItem.target.filename}
        target_filename={todoItem.target.filename}
        folderId={todoItem.target.folder}
        label={""}
        externalSubmitEvent={""}
        onCancel={() => {
          resetModal();
        }}
        handleUploadSuccess={() => {
          finishTodo(todoItem, () => {
            resetModal();
            refreshTodosFromServer();
          });
        }}
      />
    );
  };

  const renderModalFormContent = (todoItem: any) => {
    return (
      <FindingForm
        withPaper={false}
        findingId={todoItem.target.finding}
        formId={todoItem.target.form}
        preSubmitConfirmMessage={t("confirm_submit_finding_form")}
        onSuccessfulFormSubmitHandler={() => {
          finishTodo(todoItem, () => {
            resetModal();
            refreshTodosFromServer();
          });
        }}
        onCancelFormHandler={() => {
          resetModal();
        }}
      />
    );
  };

  const getModalContent = (todoItem: any) => {
    let modalContent = <></>;
    switch (todoItem.type) {
      case "upload":
        modalContent = renderModalUploadContent(todoItem);
        break;
      case "form":
        modalContent = renderModalFormContent(todoItem);
        break;
    }

    return modalContent;
  };

  const openTodoActionInModal = (todoItem: any) => {
    setModalContent(getModalContent(todoItem));
    setModalOpen(true);
    setModalTitle(todoItem.target.filename);
  };
  let todosDone = <></>;
  let todosList = (
    <Grid container>
      <Grid item component={Paper} className="allDoneContainer">
        <h1 className="allDoneHeading">{t("all_done")}</h1>
        <div className="allDoneContentWrapper">
          <h4 className="allDoneContentHeading">
            <Trans i18nKey={"todo_all_done_message"} />
          </h4>
          <HighFiveIcon width="160" height="159" />
        </div>
      </Grid>
    </Grid>
  );

  if (todos) {
    for (const key in todos) {
      const todoItem: any = todos[key];
      const remainingDays = dayjs(todoItem.due_date).diff(today, "day");
      const buttonsConfig = [];
      if (!todoItem.done) {
        if (todoItem.type === "text") {
          buttonsConfig.push({
            tooltip: t("mark_todo_as_done"),
            icon: "checkmark",
            backgroundColor: "#73be2a",
            onClick: () => {
              if (confirm(t("mark_todo_as_done_question"))) {
                finishTodo(todoItem, () => {
                  refreshTodosFromServer();
                });
              }
            },
          });

          if (
            todoItem.target &&
            todoItem.target.link &&
            todoItem.target.linkTitle
          ) {
            buttonsConfig.push({
              tooltip: todoItem.target.linkTitle,
              icon: "arrowRight",
              backgroundColor: "#ED6C02",
              onClick: () => {
                window.open(
                  todoItem.target.link,
                  "_blank",
                  "noopener,noreferrer"
                );
              },
            });
          }
        } else {
          buttonsConfig.push({
            icon: "arrowRight",
            backgroundColor: "#ED6C02",
            onClick: () => {
              openTodoActionInModal(todoItem);
            },
          });
        }
      }
      const item = (
        <Grid item sm={12} key={todoItem.id} className="todoItem">
          <GeneralListItem
            key={todoItem.id}
            buttonsConfig={buttonsConfig}
            wrapperClassName="generalListItemWrapper"
            allowHover={false}
            buttonWidth={isMobile ? "40vw" : undefined}
            borderRadius={isMobile ? "0.5rem" : undefined}
          >
            <div className="todoItemContentWrapper">
              {!isMobile || todoItem.done ? (
                <div className="todoCheckboxRound">
                  <input
                    type="checkbox"
                    checked={todoItem.done}
                    id={`todoDone-${todoItem.id}`}
                    readOnly={true}
                  />
                  <label htmlFor={`todoDone-${todoItem.id}`}></label>
                </div>
              ) : null}
              <ListItem className="todoListItem">
                <ListItemText primary={todoItem.name} />
                {!isMobile ? (
                  <ListItemText
                    secondary={todoItem.description}
                    className="todoItemDescription"
                    style={{ whiteSpace: "pre-line" }}
                  />
                ) : (
                  <ListItemText
                    primary={todoItem.description}
                    className="todoItemDescription"
                    style={{ whiteSpace: "pre-line" }}
                  />
                )}
                <ListItemText
                  className="todoListItemText"
                  primary={
                    <div className="listItemTextWrapper">
                      {!todoItem.done && (
                        <Typography
                          component={"span"}
                          color="text.primary"
                          className="remainingDays"
                        >
                          <span className="remainingDaysIconWrapper">
                            <FlagIcon width="12" height="14" />
                          </span>
                          <Trans
                            i18nKey={
                              remainingDays <= 1
                                ? t("remaining_day", { day: remainingDays })
                                : t("remaining_days", { days: remainingDays })
                            }
                          />
                        </Typography>
                      )}
                      <Typography color="text.secondary">
                        {t("todo_until", {
                          date: dayjs(todoItem.due_date).format("DD.MM.YYYY"),
                        })}
                      </Typography>
                    </div>
                  }
                />
              </ListItem>
            </div>
          </GeneralListItem>
        </Grid>
      );
      if (todoItem.done) {
        todoListDone.push(item);
      } else {
        todoListOpen.push(item);
      }
    }

    if (todoListOpen.length > 0) {
      todosList = (
        <Grid container item>
          <h2 className="todoContainerHeading">{t("open")}</h2>
          <Grid container item sm={12} component={List} rowSpacing={"1rem"}>
            {todoListOpen}
          </Grid>
        </Grid>
      );
    }
    if (todoListDone.length > 0) {
      todosDone = (
        <Grid container item>
          <h2 className="todoContainerHeading">{t("done")}</h2>
          <Grid container item sm={12} component={List} rowSpacing={"1rem"}>
            {todoListDone}
          </Grid>
        </Grid>
      );
    }
  }

  return (
    <>
      <Grid container rowSpacing={"1rem"}>
        <Grid item xs={12}>
          <h1 className="todosHeader">Deine To Dos</h1>
        </Grid>
        {todosList}
        {todosDone}
        <Modal
          open={modalOpen}
          title={modalTitle}
          hideDialogActions={true}
          onClose={() => setModalOpen(false)}
        >
          {modalContent}
        </Modal>
      </Grid>
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
}
