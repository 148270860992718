import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  ListItem,
} from "@mui/material";
import { FC, useState } from "react";
import FileTableIcon from "../files/fileTableIcons";
import { t } from "i18next";
import AppointmentTreeItemUploadButton from "./appointmentTreeItemUploadButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "./appointmentTreeItemFileList.module.css";

interface Props {
  appointment: any;
}

const AppointmentTreeItemFileList: FC<Props> = ({ appointment }) => {
  const [expanded, setExpanded] = useState<string | false>("fileList");
  let appointmentFilesList: any = <></>;
  const renderAppointmentFilesList = () => {
    const files: any = {};
    const renderedListItems = [];
    for (const fileNumber in appointment.file_set) {
      const fileItem = appointment.file_set[fileNumber];
      files[fileItem.name] = fileItem.view_link;
    }

    for (const filename in files) {
      const pretifiedFilename = filename.replace("_", " ");
      renderedListItems.push(
        <ListItem
          key={files[filename]}
          className={styles.listItem}
        >
          <FileTableIcon extension={"pdf"} />
          <a
            className={styles.link}
            href={"/api/v1" + files[filename]}
            target="_blank"
            rel="noreferrer"
          >
            {pretifiedFilename}
          </a>
        </ListItem>
      );
    }

    if (renderedListItems.length > 0) {
      return renderedListItems;
    }

    return false;
  };

  if (appointment) {
    appointmentFilesList = renderAppointmentFilesList();
  }

  const handleExpand =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Grid item container xs={12}>
      <Grid item xs={12}>
        <Accordion
          className={styles.accordion}
          expanded={expanded === "fileList"}
          onChange={handleExpand("fileList")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className={styles.accordionSummary}>
              <h2 className={styles.accordionTitle}>
                {t("generated_documents")}
              </h2>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {expanded === "fileList" && (
              <AppointmentTreeItemUploadButton
                appointment={appointment}
                useCase="documentWidget"
              />
            )}
            {appointmentFilesList}
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default AppointmentTreeItemFileList;
