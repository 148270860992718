import { ErrorMessage } from "@hookform/error-message";
import { FC, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import CustomErrorMessage from "../../forms/inputsWithRef/customErrorMessage";
import { DotLoader } from "react-spinners";
import { t } from "i18next";
import Select from "react-select";
import styles from "./genericCrudSelect.module.css";

interface Props {
  name: any;
  control: any;
  required: boolean;
  errorMessage: string;
  entity: string | undefined;
  label: string;
  id: any;
  values: any;
  onHandleChange: (
    event: any,
    onChange: any,
    multiselect?: boolean,
    valueArray?: any
  ) => void;
  entityItems: any;
  modalMode: string | undefined;
  multiSelect: boolean | undefined;
  presetOptions?: any;
  getValues?: any;
  getOptionsFinished?: boolean;
  showAddressForBuildingSelect?: boolean;
}

const GenericCrudSelect: FC<Props> = ({
  name,
  control,
  required,
  errorMessage,
  entity,
  label,
  id,
  // values,
  onHandleChange,
  entityItems,
  // modalMode,
  multiSelect,
  // presetOptions,
  getValues,
  getOptionsFinished,
  showAddressForBuildingSelect,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [options, setOptions] = useState<any>([]);
  useEffect(() => {
    const tempOptions = [];
    if (entityItems && entityItems.length !== 0) {
      for (const key in entityItems) {
        const item = entityItems[key];
        const value = item.id;
        let label = t(item.name) === "" ? t(item.label) : t(item.name); //selectOptions hardcoded in Columns with label

        if (showAddressForBuildingSelect) {
          const labelParts = [item.name];
          const details = [];
          if (item.street_house !== "") details.push(item.street_house);

          if (item.zip || item.city) {
            const combined = [item.zip, item.city]
              .filter((part) => part !== "")
              .join(" ");
            details.push(combined);
          }
          if (details.length > 0) {
            labelParts.push(`(${details.join(", ")})`);
          }

          label = labelParts.join(" ");
        }
        if (entity === "user") {
          label = item.last_name + ", " + item.first_name;
        }
        tempOptions.push({ value: value, label: label });
      }
      setOptions(
        tempOptions.sort((a: any, b: any) => a.label.localeCompare(b.label))
      );
      // setIsLoading(false);
    }
  }, [entityItems]);
  useEffect(() => {
    if (options.length !== 0) {
      setIsLoading(false);
    } else if (options.length === 0 && getOptionsFinished) {
      setIsLoading(false);
    }
  }, [options, getOptionsFinished]);

  const findOptionForValue = (value: any) => {
    for (const key in options) {
      const option = options[key];
      if (value === option.value) {
        return option;
      }
    }
  };

  const getValuesForField = (value: any) => {
    if (typeof value === "string") {
      return findOptionForValue(value);
    }
    if (typeof value === "boolean") {
      return findOptionForValue(String(value));
    } else {
      const multiValues = [];
      for (const key in value) {
        const option = findOptionForValue(value[key]);
        if (option) {
          multiValues.push(option);
        }
      }
      return multiValues;
    }
  };
  const handleChange = (event: any, onChange: any) => {
    const valueArray = [];
    for (const key in event) {
      const value = event[key].value;
      valueArray.push(value);
    }

    if (multiSelect) {
      onHandleChange(
        {
          target: {
            value: event.value,
            name: event.label,
          },
        },
        onChange,
        true,
        valueArray
      );
    } else {
      onHandleChange(
        {
          target: {
            value: event?.value,
            name: event?.label,
          },
        },
        onChange
      );
    }
  };

  const disableSelect = (): boolean => {
    if (name === "managed_department_set") {
      if (getValues("manager_type") === "standard_manager") {
        return true;
      }
      if (name === "examination" && getValues("category") === "") {
        return true;
      }
    }
    return false;
  };

  const isOptionDisabled = (option: any) => {
    return option.label === "Abteilungsleiter";
  };

  return (
    <>
      {isLoading ? (
        <div className={styles.loaderWrapper}>
          <DotLoader color="#8c1ec8" size={30} />
        </div>
      ) : (
        <Controller
          name={name as string}
          control={control}
          rules={{
            required: {
              value: required,
              message: errorMessage,
            },
          }}
          render={({
            field: { onChange, value, ...rest },
            fieldState: { error },
            formState: { errors },
          }) => (
            <div className={styles.selectWrapper}>
              <label className={styles.dropdownWithRefLabel}>
                {label}
                {required === true && (
                  <span className={styles.labelRequired}>{"\u{002A}"}</span>
                )}
              </label>
              <Select
                {...rest}
                isMulti={multiSelect}
                value={getValuesForField(value)}
                options={options}
                isOptionDisabled={(option) => isOptionDisabled(option)}
                isClearable={true}
                isSearchable={true}
                isDisabled={disableSelect()}
                id={id}
                onChange={(event: any) => handleChange(event, onChange)}
                menuPortalTarget={document.body}
                styles={{
                  placeholder: (baseStyles) => ({
                    ...baseStyles,
                    color: error ? "#d32f2f" : "",
                  }),
                  input: (baseStyles) => ({
                    ...baseStyles,
                    margin: "0",
                    padding: "0",
                  }),

                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderColor: error ? "#d32f2f" : "rgba(0, 0, 0, 0.23)",
                  }),
                  valueContainer: (baseStyles) => ({
                    ...baseStyles,
                    padding: "0.75rem",
                    borderRadius: "0.5rem",
                  }),

                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),

                  option: (styles, { isDisabled, isFocused, isSelected }) => {
                    return {
                      ...styles,
                      backgroundColor: isDisabled
                        ? undefined
                        : isSelected
                          ? "#f4f2f2"
                          : isFocused
                            ? "#a34bd3"
                            : undefined,
                      color: isDisabled
                        ? "#bcbcbc"
                        : isSelected
                          ? "black"
                          : isFocused
                            ? "white"
                            : undefined,

                      cursor: isDisabled ? "not-allowed" : "default",
                    };
                  },
                }}
              />
              <ErrorMessage
                errors={errors}
                name={name as string}
                render={({ message }) => (
                  <CustomErrorMessage error={"error"} errorMessage={message} />
                )}
              />
            </div>
          )}
        />
      )}
    </>
  );
};

export default GenericCrudSelect;
